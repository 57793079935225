import React, { useContext } from 'react'
import AssignmentWelcome from './AssignmentWelcome'
import WelcomeScreen from './WelcomeScreen'
import Loading from './Loading'
import AudioScreen from './AudioScreen'
import TestInstructions from './TestInstructions'
import AttemptedRecently from './AttemptedRecently'
import FaceName from './FaceName/Test'
import Groceries from './Groceries/Groceries'
import ThankYou from './ThankYou'
import RequestID from './RequestID'
import AreYouThere from './AreYouThere'
import CategoryQuestion from './CategoryQuestion'
import SignQuestion from './Signs/Question'
import TimerExplanation from './TimerExplanation'
import FNameLearning from './FName/Learning'
import FNameMatching from './FName/Matching'
import FNameRecall from './FName/Recall'
import FNameRecognition from './FName/Recognition'
import DigitSign from './DigitSign/DigitSign'
import { StoreContext } from '../StoreContext'
import {
  RC_AUDIO_EXP,
  RC_CATEGORIES_LEARNING,
  RC_CATEGORIES_RECALL,
  RC_FACE_NAME_ASSOCIATE,
  RC_FACE_NAME_LEARN,
  RC_FACE_NAME_RECOGNIZE,
  RC_FNAME_LEARNING,
  RC_FNAME_MATCHING,
  RC_FNAME_RECALL,
  RC_FNAME_RECOGNITION,
  RC_GROCERIES_LEARN,
  RC_GROCERIES_PAIR,
  RC_GROCERIES_PICMATCHING,
  RC_GROCERIES_RECALL,
  RC_INSTRUCTIONS,
  RC_SIGN,
  RC_TIMER_EXP,
  RC_THANK_YOU,
  RC_SURVEY,
  RC_DIGIT_SIGN,
  RC_DIGIT_SIGN_RECALL,
} from '../constants'
import Survey from './Survey/Survey'
import DigitSignRecall from './DigitSignRecall/DigitSignRecall'
import AssignmentFeedbackStandalone from './AssignmentFeedbackStandalone'
import ConsentForm from './Consent/ConsentForm'
import DeniedConsent from './Consent/DeniedConsent'

const Router = () => {
  const store = useContext(StoreContext)

  if (process.env.NODE_ENV === 'development') {
    const storeSnapshot = {}
    Object.keys(store).forEach((key) => {
      if (typeof store[key] !== 'function') {
        storeSnapshot[key] = store[key]
      }
    })
    console.log(storeSnapshot)
  }
  if (
    store.requiresConsent &&
    store.userConsented === null &&
    store.isLoading === false
  ) {
    return <ConsentForm />
  }
  if (store.requiresConsent && store.userConsented === false) {
    return <DeniedConsent />
  }
  if (
    store.sequenceId &&
    store.requiresConsent === true &&
    store.userConsented !== true &&
    store.userProvidedID !== null &&
    store.isLoading === false
  ) {
    return <ConsentForm />
  }
  if (
    (store.sequenceId || store.selfServeSlug) &&
    store.userProvidedID !== null &&
    store.requiresConsent === false &&
    store.isLoading === false
  ) {
    let href = `/${store.language}/test/${store.organization}/seq/${store.sequenceId}/instructions/?id=${store.userProvidedID}`
    if (store.selfServeSlug) {
      href = `/${store.language}/self-serve/${store.selfServeSlug}/instructions/?id=${store.userProvidedID}`
    } else if (store.displayFeedbackQP !== null) {
      href += `&df=${store.displayFeedbackQP}`
    }
    // Wait half a second to redirect the user.
    // Attempt to solve the "connection already closed" error
    setTimeout(() => {
      window.location.href = href
    }, 500)
    // Return loading screen until the page refreshes
    return <Loading />
  }
  if (
    (store.sequenceId || store.selfServeSlug) &&
    store.isLoading === false &&
    (store.userConsented === true || store.requiresConsent === false)
  ) {
    let href = `/${store.language}/test/${store.organization}/seq/${store.sequenceId}/instructions/?id=${store.userProvidedID}`
    if (store.selfServeSlug) {
      href = `/${store.language}/self-serve/${store.selfServeSlug}/instructions/?id=${store.userProvidedID}`
    } else if (store.displayFeedbackQP !== null) {
      href += `&df=${store.displayFeedbackQP}`
    }
    // Wait half a second to redirect the user.
    // Attempt to solve the "connection already closed" error
    setTimeout(() => {
      window.location.href = href
    }, 500)
    // Return loading screen until the page refreshes
    return <Loading />
  }
  if (store.assignment && store.subPath === 'results') {
    return <AssignmentFeedbackStandalone />
  }
  if (store.assignment && store.testVariationId === null) {
    return <AssignmentWelcome />
  }

  if (!store.assignmentId && !store.userProvidedID) {
    return <RequestID />
  }

  if (store.isLoading) {
    return <Loading />
  }

  if (
    store.assignmentId === null &&
    store.idAttemptedRecently.has_recent_submission
  ) {
    return <AttemptedRecently />
  }

  if (store.shouldAskUserReady) {
    return <AreYouThere />
  }

  if (store.questionCount === 0) {
    throw new Error('This phase has no questions setup.')
  }

  if (store.location.phase === 0 && !store.afterFeedback) {
    return <WelcomeScreen key={store.location.phase} />
  }

  const phase = store.getPhase(store.afterFeedback)

  const buildTestInstructions = () => {
    return (
      <TestInstructions
        key={store.location.phase}
        title={phase.instructions_title}
        body={phase.instructions_body}
      />
    )
  }

  if (phase === undefined || phase.react_component === undefined) {
    throw new Error(store.getString('genericError'))
  }

  switch (phase.react_component) {
    case RC_TIMER_EXP:
      return <TimerExplanation />
    case RC_AUDIO_EXP:
      return <AudioScreen />
    case RC_INSTRUCTIONS:
      return buildTestInstructions()
    case RC_FACE_NAME_ASSOCIATE:
    case RC_FACE_NAME_LEARN:
    case RC_FACE_NAME_RECOGNIZE:
      return <FaceName key={store.location.question} />
    case RC_FNAME_LEARNING:
      if (store.location.question === 0) {
        return buildTestInstructions()
      }
      return (
        <FNameLearning
          key={store.location.question}
          q={store.getQuestion(store.afterFeedback)}
        />
      )
    case RC_FNAME_MATCHING:
      if (store.location.question === 0) {
        return buildTestInstructions()
      }
      return (
        <FNameMatching
          key={store.location.question}
          q={store.getQuestion(store.afterFeedback)}
        />
      )
    case RC_FNAME_RECALL:
      if (store.location.question === 0) {
        return buildTestInstructions()
      }
      return (
        <FNameRecall
          key={store.location.question}
          q={store.getQuestion(store.afterFeedback)}
        />
      )
    case RC_FNAME_RECOGNITION:
      if (store.location.question === 0) {
        return buildTestInstructions()
      }
      return (
        <FNameRecognition
          key={store.location.question}
          q={store.getQuestion(store.afterFeedback)}
        />
      )
    case RC_CATEGORIES_LEARNING:
    case RC_CATEGORIES_RECALL:
      if (store.location.question === 0) {
        return buildTestInstructions()
      }
      return (
        <CategoryQuestion
          testTitle={phase.name}
          question={store.getQuestion(store.afterFeedback)}
          key={store.location.question}
        />
      )
    case RC_SIGN:
      if (store.location.question === 0) {
        return buildTestInstructions()
      }
      return (
        <SignQuestion
          sign={store.getQuestion(store.afterFeedback)}
          key={store.location.question}
        />
      )
    case RC_SURVEY:
      if (store.location.question === 0) {
        return buildTestInstructions()
      }
      return (
        <Survey
          key={store.location.question}
          q={store.getQuestion(store.afterFeedback)}
        />
      )
    case RC_DIGIT_SIGN:
      if (store.location.question === 0) {
        return buildTestInstructions()
      }
      return (
        <DigitSign
          key={store.location.question}
          q={store.getQuestion(store.afterFeedback)}
          p={phase}
        />
      )
    case RC_DIGIT_SIGN_RECALL:
      if (store.location.question === 0) {
        return buildTestInstructions()
      }
      return (
        <DigitSignRecall
          key={store.location.question}
          q={store.getQuestion(store.afterFeedback)}
          p={phase}
        />
      )
    case RC_GROCERIES_LEARN:
    case RC_GROCERIES_PAIR:
    case RC_GROCERIES_PICMATCHING:
    case RC_GROCERIES_RECALL:
      return <Groceries key={store.location.question} />
    case RC_THANK_YOU:
      return <ThankYou />
    default:
      throw new Error(store.getString('genericError'))
  }
}

export default Router
